import React, { useState } from 'react';
import { Link, graphql } from 'gatsby';
import styled, { keyframes } from 'styled-components';
import PropTypes from 'prop-types';
import BackgroundImage from 'gatsby-background-image';

import Layout from '../components/layout';
import SEO from '../components/seo';
//import catAndHumanIllustration from '../images/cat-and-human-illustration.svg';

const cards = [
  {
    title: 'Выбрать программу 1С',
    description: 'купить или арендовать',
    link: '/products',
    img: 'odins',
    about:
      'Поможем Вам выбрать продукт, наиболее подходящий под Ваши задачи и бюджет.',
  },
  {
    title: 'Сопровождение 1С',
    description: '1С:ИТС. Техническая и консультационная поддержка',
    link: '/services',
    img: 'its',
    about:
      'Оказываем полный комплекс услуг по программам 1С. От продажи лицензии до сопровождения.',
  },
  {
    title: 'Автоматизация магазинов',
    description: 'продажа и внедрение ПО и оборудования',
    link: '/shops',
    img: 'shops',
    about: 'Автоматизация магазинов различных форматов "под ключ"',
  },
  {
    title: 'Разработка интернет-сайтов',
    description:
      'высокая степень оптимизации. готовый интернет-магазин за 1 день!',
    link: '/sites',
    img: 'sites',
    about: 'Разрабатываем сайты и интернет-приложения для Ваших бизнес-задач.',
  },
];

const FadeIn = keyframes`
0% {
  opacity:0;
}
100% {
  opacity:1;
  }
`;

const StyledBackground = styled(BackgroundImage)`
  /* animation: 0.3s ${FadeIn} ease-in; */
`;

const StyledTitle = styled.h2`
  line-height: 1.5;
  text-align: center;
  box-sizing: border-box;
  border: 0 solid #e2e8f0;
  font-weight: inherit;
  border-radius: 0.25rem;
  height: 8rem;
  margin: 0 auto;
  /* margin-bottom: 0.5rem; */
  max-width: 72rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  padding-right: 1rem;
  color: #fefcbf;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  letter-spacing: 0.05em;
  font-size: 1.1rem;
  @media (min-width: 640px) {
     {
      font-size: 1.125rem;
      margin-bottom: 0;
    }
  }
  @media (min-width: 768px) {
     {
      font-size: 1.5rem;
    }
  }
  @media (min-width: 1024px) {
     {
      font-size: 1.875rem;
    }
  }
  animation: 0.8s ${FadeIn} ease-in;
`;

function IndexPage(props) {
  const [image, setImage] = useState('main');
  const [about, setAbout] = useState(
    'Есть такая профессия - Родину автоматизировать!'
  );

  return (
    <Layout>
      <SEO
        keywords={[
          `Программы 1С`,
          `Пыть-Ях`,
          `Югра`,
          `аренда 1С`,
          'Купить 1С Пыть-Ях',
          'Купить 1С Пушкин',
          'Разработка сайтов Пыть-Ях',
          'Разработка сайтов Пушкин',
        ]}
        title='Главная'
      />
      <StyledBackground
        className='h-full md:h-screen bg-cover bg-no-repeat bg-center bg-black'
        fluid={
          image === 'main'
            ? props.data.main.childImageSharp.fluid
            : image === 'odins'
            ? props.data.odins.childImageSharp.fluid
            : image === 'its'
            ? props.data.its.childImageSharp.fluid
            : image === 'shops'
            ? props.data.shops.childImageSharp.fluid
            : props.data.sites.childImageSharp.fluid
        }
      >
        <section className='text-center flex flex-col items-center bg-g8 justify-center h-full '>
          <div className='block mx-auto '>
            {/* <h2
              className=' h-32 max-w-6xl text-base sm:text-lg md:text-2xl lg:text-3xl text-yellow-200 uppercase 
               tracking-wider mb-2 md:mb-4 inline-block px-4 py-2 rounded flex items-center justify-center m-auto'
            >
              {about}
            </h2> */}
            <StyledTitle>{about}</StyledTitle>
            <hr className=' hidden lg:block' />
            <div id='group' className='md:flex flex-wrap my-8 '>
              {cards.map(({ about, title, description, link, img }) => (
                <Link
                  key={title}
                  to={link}
                  className='bg-transparent flex-1 flex-auto flex flex-col md:w-1/3 lg:w-1/5
                  justify-center border rounded bg-g8h m-2 p-6 text-lg '
                  onMouseEnter={() => {
                    setImage(img);
                    setAbout(about);
                    console.log(image);
                  }}
                >
                  <p
                    className='uppercase font-bold text-gray-400
                  transition duration-500 ease-in-out
                  transform hover:-translate-y-1 hover:scale-110 '
                  >
                    {title}
                  </p>
                  <p className='text-base text-yellow-200'>{description}</p>
                </Link>
              ))}
            </div>
          </div>

          <h3 className='text-lg my-4 font-bold  text-blue-300  tracking-wider'>
            <span className='text:sm sm:text-base block lg:inline-block mx-1'>
              Компания СОФТМАКС является официальным
            </span>{' '}
            партнером-франчайзи фирмы 1С с 2004 года.
          </h3>
        </section>
      </StyledBackground>
    </Layout>
  );
}
export const pageQuery = graphql`
  query {
    main: file(relativePath: { eq: "images/main.jpg" }) {
      childImageSharp {
        fluid(quality: 80, maxWidth: 1800) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    odins: file(relativePath: { eq: "images/window1s.jpg" }) {
      childImageSharp {
        fluid(quality: 80, maxWidth: 1800) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    its: file(relativePath: { eq: "images/odins.jpg" }) {
      childImageSharp {
        fluid(quality: 80, maxWidth: 1800) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    shops: file(relativePath: { eq: "images/shops.jpg" }) {
      childImageSharp {
        fluid(quality: 80, maxWidth: 1800) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    sites: file(relativePath: { eq: "images/sites.jpg" }) {
      childImageSharp {
        fluid(quality: 80, maxWidth: 1800) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`;

IndexPage.propTypes = {
  data: PropTypes.object,
};

export default IndexPage;
